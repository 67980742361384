import { Carousel, Col, Container, Row } from 'react-bootstrap';
import Logo from './assets/images/bancofibra_logo.png';
import Banner1 from './assets/images/slide_img1.jpeg';
import IconFacebook from './assets/images/facebook-1.png';
import IconLinkedin from './assets/images/linkedin-1.png';
import IconInstagram from './assets/images/instagram-1.png';
import IconYoutube from './assets/images/youtube-1.png';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './Helpers.css';

function App() {
  return (
    <div>
      <Container>
        <header className="App-header py-3">
          <Row>
            <Col xs lg="2">
              <img src={Logo} id="logo" alt="" className="img-fluid" />
            </Col>
            <Col xs lg="10" className="d-flex justify-content-end">
              <button
                className="btn-blue"
                onClick={() => {
                  window.location.replace(
                    'https://portal.investimento.bancofibra.com.br?firstLogin=true'
                  );
                }}
              >
                Acessar conta
              </button>
              <button
                className="btn-blue ml-2"
                onClick={() => {
                  window.location.replace(
                    'https://portal.investimento.bancofibra.com.br/abertura-de-conta?firstLogin=true'
                  );
                }}
              >
                Criar conta
              </button>
            </Col>
          </Row>
        </header>
      </Container>
      <section id="banners">
        <Carousel indicators={false} interval={null} controls={false}>
          <Carousel.Item>
            <div
              className="slide-container"
              style={{ backgroundImage: `url(${Banner1})` }}
            >
              <div className="hover-slide"></div>
              <div className="caption">
                <div className="caption-content">
                  <h1>Investimentos</h1>
                  <p>Aqui você investe de forma prática e segura.</p>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      {/* <section id='aplicacoes'>
        <Container className='py-5'>
          <Row>
            <Col lg="6" className='d-flex align-items-center'>
              <div>
                <h1>
                  Agora ficou fácil investir com o Banco Fibra:              
                </h1>
                <h1 className='text-bold'>
                  Plataforma 100% Digital.
                </h1>
              </div>
            </Col>
            <Col lg="6">
              <img src={AplicacaoFibra} alt="" />
            </Col>
          </Row>
        </Container>
        <Container>
          <span className="arrow1"></span>
          <Row className='card-row'>
            <Col className="col-md align-self-start">
              <h3>Invista Já</h3>
              <p>
              O cadastro é totalmente digital e com taxa zero. Abrir sua conta é
              de graça e leva poucos minutos.
              </p>
            </Col> 
            <Col className="col-md align-self-center">
                <h3>Escolha seu Investimento</h3>
                <p>
                Faça simulações e compare as melhores opções de investimento que
                o Banco Fibra pode te oferecer.
                </p>
              </Col>
            <Col className="col-md align-self-end">
              <h3>Comece a investir</h3>
              <p>
                Com a plataforma digital do Banco Fibra, investir não é algo
                complicado. Abra a sua conta e tenha um atendimento especializado.
                Nossa sólida experiência fará você crescer.
              </p>
            </Col>
          </Row>
          <span className="arrow2"></span>
        </Container>
      </section> */}

      {/* <section id="beneficios">
        <div class="w-100 d-flex justify-content-start">
          <div className='benefits__item--solucoes'>
            <div class="benefits__text benefits__text--solucoes">
              <h3>Soluções para Você</h3>
              <p>
                O Banco Fibra está preparado para atender as mais diversas demandas
                de soluções financeiras. Invista nas melhores opções de CDB, LCI e
                LCA com o Banco que consolidou presença no cenário econômico.
              </p>
            </div>
          </div>
        </div>        
        <div className='w-100 d-flex justify-content-end'>
          <div class="benefits__item benefits__item--diferenciais">
            <div class="benefits__text benefits__text--diferenciais">
              <h3>Nossos Diferenciais</h3>
              <ul className='list-unstyled'>
                <li>Atendimento personalizado;</li>
                <li>Agilidade;</li>
                <li>Competência no desenvolvimento de soluções;</li>
                <li>Relacionamento de longo prazo.</li>
                </ul>
            </div>
          </div>
        </div>
      </section> */}

      <footer className="pt-5">
        <Container>
          <Row>
            <Col lg="4">
              <div className="investor-relationship">
                <strong>Relação com Investidores</strong> <br />
                <a className="channels-button" href="tel:+551138476800">
                  (11) 3847-6800
                </a> <br />
                <a
                  className="channels-button"
                  href="mailto:atendimentodigital@bancofibra.com.br"
                >
                  atendimentodigital@bancofibra.com.br
                </a>
                <p>
                  Das 10h às 17h, de segunda a sexta-feira, exceto feriados.
                </p>
              </div>
            </Col>
            <Col lg="4">
              <div>
                <strong>SAC</strong> <br />
                <p>
                  Canal de Atendimento ao Cliente <br />
                  <a className="channels-button" href="tel:+5508007270132">
                    0800 727 0132
                  </a>
                </p>
              </div>
              <div>
                <strong>Ouvidoria</strong> <br />
                <a className="channels-button" href="tel:+5508007270132">
                  0800 727 0132
                </a>
                <br />
                <p>
                  Das 10h às 16h, de segunda a sexta-feira, exceto feriados.
                </p>
              </div>
              <div>
                <p>
                  <strong>Alô Ética</strong>
                  <br />
                  Canais de acesso: <br />
                  <a className="channels-button" href="tel:+5508007182837">
                    0800 718 2837
                  </a>
                  <br />
                  <a
                    className="channels-button"
                    href="mailto:bancofibra@aloetica.com.br"
                  >
                    bancofibra@aloetica.com.br
                  </a>
                  <br />
                  <a
                    className="channels-button"
                    href="https://www.aloetica.com.br/bancofibra"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    www.aloetica.com.br/bancofibra
                  </a>
                </p>
              </div>
            </Col>
            <Col lg="4">
              <div className="mt-4">
                <a
                  href="https://www.facebook.com/BancoFibra/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={IconFacebook} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/banco-fibra/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={IconLinkedin} alt="" />
                </a>
                <a
                  href="https://www.instagram.com/bancofibra/"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={IconInstagram} alt="" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCn7BJ_cq5ZCimPFKA8VL72A"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  <img src={IconYoutube} alt="" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <div className="address">
            <Container>
              <div
                style={{
                  fontSize: 12,
                }}
                onClick={() => {
                  window.open(
                    'https://www.google.com/maps/place/Banco+Fibra/@-23.5860594,-46.6736055,15z/data=!4m5!3m4!1s0x0:0x1f83ff822f35c4c1!8m2!3d-23.5860815!4d-46.6735702'
                  );
                }}
              >
                <p>
                  © Copyright 2021 Fibra. Todos os direitos reservados. <br />
                  AVENIDA DOUTORA RUTH CARDOSO, 8.501 - 14º E 15º ANDAR (PARTE)
                  - CEP: 05425-070 – PINHEIROS - SÃO PAULO/SP
                </p>
              </div>
            </Container>
          </div>
        </Row>
      </footer>
    </div>
  );
}

export default App;
